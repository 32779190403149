import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import pic01 from '../assets/images/pic01.jpg'
import faviconIco from '../assets/images/favicon.ico'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter= () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {
    const title = "Durga Trading Company - Estd. 1978 - Agri Produced Commodities Broker";
    const description = "Estd. in 1978, Durga Trading Company is a brokerage (commission agent) firm dealing in Cotton-Seed, Cotton-Seed Oil, and Cotton-Seed Oil Cake (Kapasi Khali in Hindi). We primarily caters to Cotton-Ginning, Agro Industries, Cotton-Oil Mills, and Commodity-Traders. We also cater to commodity stock brokering firms in NCDEX for Cotton-Seed Oil Cake.";
    return (
      <Layout>
        <Helmet title="Durga Trading Company - Estd. 1978 - Agri Produced Commodities Broker" />
        <Helmet>
          <meta name="description" content="Estd. in 1978, Durga Trading Company is a brokerage (commission agent) firm dealing in Cotton-Seed, Cotton-Seed Oil, and Cotton-Seed Oil Cake (Kapasi Khali in Hindi). We primarily caters to Cotton-Ginning, Agro Industries, Cotton-Oil Mills, and Commodity-Traders. We also cater to commodity stock brokering firms in NCDEX for Cotton-Seed Oil Cake." />
          <meta name="google-site-verification" content="t4FecIKVVKqHf99iPdd08HawNOYSLiZu8OJFgwTpn9s" />
          <link rel="shortcut icon" href={faviconIco} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={faviconIco} />
        </Helmet>
        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Introduction</h2>
                </header>
                <p>Founded by <b>Late “Guru” Gokulchand Thanvi</b> in year 1978 at Cotton City <a target="_blank" href="https://en.wikipedia.org/wiki/Khamgaon"> <i>Khamgaon</i></a>, Shri Durga Trading Company is a renowned brokerage firm dealing in Cotton-Seed, Cotton-Seed Oil, Cotton-Seed Oil Cake (Kapasiya Khali in Hindi), Cattle feed and Agro Products.</p>
                <p>Our Company caters to Cotton-Ginning, Agro Industries, Cotton-Oil Mills, and Commodity-Traders. We also cater to commodity stock brokering firms in NCDEX for Cotton-Seed Oil Cake.</p>
                <p>Our Company is drawn from blend of rich experience in all the fields of Cotton, deep understanding of the Cotton market in India and dedication shared with its clients throughout its lifetime.</p>
                    {/*<ul className="actions">
                  <li><Link to="/generic" className="button">Learn More</Link></li>
                </ul>*/}
              </div>
              {/*<span className="image"><img src={pic01} alt="" /></span>*/}
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>About us</h2>
            </header>
            <ul className="statistics">
              <li className="style3">
                <span className="icon fa-map-marker"></span>
                <strong>Strategic</strong> Location
              </li>
              <li className="style1">
                <span className="icon fa-calendar"></span>
                <strong>{'40'}</strong> Years of Experience
              </li>
              <li className="style2">
                <span className="icon fa-map"></span>
                <strong>Pan India</strong> Reach
              </li>
              {/*<li className="style4">
                <span className="icon fa-link"></span>
                <strong>Cotton Seed Value Chain</strong>Complete
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,024</strong> Nullam
              </li>*/}
            </ul>
            <div className="content">
              <header className="minor">
                <h2><b>Founder - Gokulchand Ishwardas Thanvi</b></h2>
              </header>
              {/*<span className="image"><img src={pic01} alt="" /></span>*/}
              <p>On the auspicious day of Dussehra in 1978, Gokulchandji Thanvi founded Shri Durga Trading Company in Khamgaon, then famously referred to Cotton City; and strategically located about 50 KM from Akola (Cotton Seed Oil Cake Basis in NCDEX).</p>
              <p>Gokulchandji possed a spirit of entrepreneurial adventure, bold decision acumen and was known for his pulse on the market, earning him the title of “Guru” among his peer and clients. He was also actively involved in social-development and philanthropy activities.</p>
                  {/*<ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>*/}
            </div>
            <div className="content">
              <header className="minor">
                <h2><b>Proprietor - Ashok Gokulchand Thanvi</b></h2>
              </header>
              {/*<span className="image"><img src={pic01} alt="" /></span>*/}
              <p>An MBA (1982) from Mumbai University, Ashok Thanvi joined Shri Durga Trading Company immediately after post-graduation. He quickly expanded the Company’s reach beyond the local and nearby markets and established Company’s presence in the now Telangana, Andhra and gradually over the south, west and north India. He is firm believer in changes in business models and methods over time and have incorporated many trend setting changes in the way services is delivered to clients.</p>
                  {/*<ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>*/}
            </div>
            {/*<p className="content">Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum ac arcu sit amet, fermentum pellentesque et purus. Integer maximus varius lorem, sed convallis diam accumsan sed. Etiam porttitor placerat sapien, sed eleifend a enim pulvinar faucibus semper quis ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer eget purus nec nulla mattis et accumsan ut magna libero. Morbi auctor iaculis porttitor. Sed ut magna ac risus et hendrerit scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras eu ornare dui curabitur lacinia.</p>*/}
            {/*<footer className="major">
              <ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>
            </footer>*/}
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Services</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-users"></span>
                <h3><b>Consultancy</b></h3>
                <p>Customized market research, logistic support and any professional assistance in getting firm footing for, new or old, Mils and Agro industries. <br/><i><b>Need Experience, Get Consultancy.</b></i></p>
              </li>
              <li>
                <span className="icon major style3 fa-handshake-o"></span>
                <h3><b>Commission Agent / Broker</b></h3>
                <p>Cotton-Seed, Cotton-Seed Oil, Cotton Seed Oil Cake, Cattle feed and Agro Products. <br/><i><b>Be assured to get SMS update at each stage of the deal.</b></i></p>
              </li>
              <li>
                <span className="icon major style5 fa-line-chart"></span>
                <h3><b>Market Rate Updates (Whatsapp)</b></h3>
                <p>It is no longer a secret that technology is shifting the way business is done. Hence, We have started to consolidate and send “professional” messages containing current rates on regular basis to our esteemed clients. <br/><i><b>Well Begun is Half done - Only fittest will survive.</b></i></p>
              </li>
            </ul>
            {/*<footer className="major">
              <ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>
            </footer>*/}
          </section>
          <section className="main special">
                <h3><b>Please take the first step, call us. </b><span className="icon fa-smile-o"></span></h3>
          </section>

          {/*<section id="cta" className="main special">
            <header className="major">
              <h2>Congue imperdiet</h2>
              <p>Donec imperdiet consequat consequat. Suspendisse feugiat congue<br />
              posuere. Nulla massa urna, fermentum eget quam aliquet.</p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li><Link to="/generic" className="button special">Get Started</Link></li>
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>
            </footer>
          </section>*/}

        </div>

      </Layout>
    )
  }
}

export default Index
