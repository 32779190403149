import React from 'react'

import logo from '../assets/images/dtclogo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img style={{ backgroundColor: 'white' }} width="30%" src={logo} alt="" /></span>
        <h3><i>Agri Produced Commodities Broker</i></h3>
    </header>
)

export default Header
